<template>
    <v-card :loading="loading" class="mx-auto">
        <template slot="progress">
            <v-progress-linear
                    color="teal darken-4"
                    height="10"
                    indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="accent-text">
            <v-row>
                <v-col cols="12" sm="6">
                    <div class="accent-text">{{tableTitle}}</div>
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <v-btn type="button" class="mr-3" v-for="(button, i) in buttons" :key="i" v-on:click="button.func(button.funcParam)"><v-icon class="mr-1" v-text="button.icon" v-if="button.icon" small></v-icon> {{button.label}}</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <!--<v-divider class="mx-4"></v-divider>-->

        <v-row no-gutters class="pl-5">
            <v-col sm="2" class="mr-2" v-for="filter in filters" :key="filter.key">
                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" v-if="filter.type === 'date'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="filter.value" :label="filter.label" readonly v-bind="attrs" v-on="on" :disabled="loading" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.value"></v-date-picker>
                </v-menu>
                <v-text-field v-else :label="filter.label" v-model="filter.value" :disabled="loading" v-on:keyup.enter="loadTableData" clearable></v-text-field>
            </v-col>

            <v-col sm="1" class="mr-2 pt-3">
                <v-btn outlined color="secondary" dark :disabled="loading" v-on:click="loadTableData"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-col>
        </v-row>

        <!--<v-divider class="mx-4"></v-divider>-->
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left" v-for="tableField in tableFields" :key="tableField.key">
                            {{tableField.label}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="tableData in tableDatas" :key="tableData.Id" @click="showDataModal(tableData)" style="cursor: pointer;">
                        <td v-for="tableField in tableFields" :key="tableField.key">
                            <span v-if="tableField.type === 'date'">{{ $globalHelpers.getFormattedDate(tableData[tableField.key]) }}</span>
                            <span v-else>{{tableData[tableField.key]}}</span>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-row no-gutters class="pa-5 pl-7">
            <v-col sm="6">
                showing {{paginationFrom}} to {{paginationTo}} of {{tableDataCount}}
            </v-col>
            <v-col sm="6" class="text-right">
                <v-btn outlined color="secondary" dark class="mr-1" :disabled="paginationPrevDisabled || loading" v-on:click="paginatePrev"><v-icon>mdi-chevron-left-circle</v-icon></v-btn>
                <v-btn outlined color="secondary" dark :disabled="paginationNextDisabled || loading" v-on:click="paginateNext"><v-icon>mdi-chevron-right-circle</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import Vue from 'vue';
    export default {
        name: "CustomDataTable",
        data: () => ({
            loading: false,
            tableDatas: [],
            tableDataCount: 0,

            menu: false,
            modal: false,
            menu2: false,
        }),
        props:{
            tableTitle:{
                type: String,
                default: ()=> 'Bangladesh Bank EXPs',
            },
            params:{
                type: Object,
                default: ()=> ({
                    Type: 'exp',
                    Skip: 0,
                    Take: 10,
                })
            },
            filters:{
                type: Array,
                default: ()=> ([
                    { key: 'BeNo', label: 'Shipping BIll No', value: null, type: 'number' },
                    { key: 'DateFrom', label: 'Date From', value: null, type: 'date' },
                    { key: 'DateTo', label: 'Date From', value: null, type: 'date' },
                    { key: 'Bin', label: 'Bin', value: null, type: 'number' },
                    { key: 'InvoiceNo', label: 'Invoice', value: null, type: 'number' },
                    { key: 'TinNo', label: 'Tin', value: null, type: 'number' },
                    { key: 'ExpNo', label: 'EXP', value: null, type: 'number' },
                    { key: 'ConNamAdr', label: 'Exporter', value: null, type: 'string' },
                ])
            },
            tableFields: {
                type: Array,
                default: ()=> ([
                    {key: 'ExpNo', label: 'Exp No', type: 'number', searchable: false},
                    {key: 'IssueDate', label: 'Exp Date', type: 'date', searchable: false},
                    {key: 'ErcName', label: 'Exporter', type: 'string', searchable: false},
                    {key: 'Bin', label: 'Bin', type: 'number', searchable: false},
                    {key: 'InvAmount', label: 'Invoice Value', type: 'number', searchable: false},
                    {key: 'BlNo', label: 'BL No', type: 'string', searchable: false},
                    {key: 'CountryIso', label: 'Country', type: 'countryIso', searchable: false},
                    {key: 'CurrencyIso', label: 'Currency', type: 'string', searchable: false},
                ])
            },
            buttons:{
                type: Array,
                default: ()=> ([])
            },
        },
        computed:{
            tableFilters: function(){
                let params = this.params;
                this.filters.forEach(function (filter) {
                    params[filter.key] = filter.value;
                });
                return params;
            },
            isTableLoadAllowed: function (){
                let allowed = false;

                this.filters.forEach(function (filter) {
                    if(filter.type === 'number' || filter.type === 'date' || filter.type === 'string'){
                        if(filter.value !== null){
                            allowed = true;
                        }

                    }
                });
                return allowed;
            },
            paginationFrom: function () {
                return this.params.Skip;
            },
            paginationTo: function () {
                let to = Number(this.params.Skip) + Number(this.params.Take);
                return to <= this.tableDataCount? to: this.tableDataCount;
            },
            paginationPrevDisabled: function () {
                return (Number(this.params.Skip) + Number(this.params.Take)) <= Number(this.params.Take);
            },
            paginationNextDisabled: function () {
                return (Number(this.params.Skip) + Number(this.params.Take)) >= Number(this.tableDataCount);
            },

        },
        methods:{
            loadTableData: async function () {
                if(this.isTableLoadAllowed) {
                    this.loading = true;
                    this.$axios.post(Vue.prototype.$globalSettings.api.endpoints.report.tableData, this.tableFilters)
                        .then((response) => {
                            this.tableDatas = this.params.Type === 'exp' ? response.data.ExpDatas : response.data.SbDatas;
                            this.tableDataCount = response.data.Count;
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.$globalHelpers.processHttpErrorsToast(error);
                        })
                }else{
                    Vue.$toast.open({message: 'Please add at least one filter!', type: 'error'});
                }
            },
            paginateNext: function () {
                this.params.Skip += this.params.Take;
                this.loadTableData();
            },
            paginatePrev: function () {
                this.params.Skip -= this.params.Take;
                this.loadTableData();
            },
            showDataModal: function (data) {
                this.$emit('show-data-modal', {type: this.params.Type, data: data});
            }
        },
        created() {
            //this.loadTableData();
        }
    }
</script>

<style scoped>

</style>