<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    <v-row no-gutters>
                        <v-col sm="6">
                            Details
                        </v-col>
                        <v-col sm="6" class="text-right">
                            <v-icon text @click="closeModal">mdi-close-thick</v-icon>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-list flat>
                        <v-list-item v-for="(item, i) in dataSegments" :key="i">
                            <v-list-item-content>
                                <v-row no-gutters>
                                    <v-col sm="4">
                                        <v-list-item-title v-text="item.label"></v-list-item-title>
                                    </v-col>
                                    <v-col sm="8">
                                        {{ item.text }}
                                        <div v-for="(subitem, j) in item.subtext" :key="j">
                                            {{subitem}}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="reSync">ReSync</v-btn>
                    <v-btn color="error" @click="deleteRow">Delete</v-btn>
                    <v-btn color="primary" @click="closeModal">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "DataRowModal",
        data () {
            return {
            }
        },
        props:{
            dialog:{
                type: Boolean,
                default: ()=> false,
            },
            type:{
                type: String,
                default: ()=> "",
            },
            data:{
                type: Object,
                default: ()=> {},
            },
            fields: {
                type: Array,
                default: ()=> [],
            }
        },
        computed:{
            dataSegments: function () {
                let segments = [];

                        //console.log(segment, this.data[segment]);
                        for(let i = 0; i < this.fields.length; i++){

                            for (let segment in this.data) {
                                if (Object.prototype.hasOwnProperty.call(this.data, segment)) {

                                    if (segment === this.fields[i].key) {
                                        if (!this.fields[i].hasChild) {
                                            let text = this.data[segment];
                                            if(this.fields[i].type === 'date' && this.data[segment] != null) {
                                                text = this.$globalHelpers.getFormattedDate(text);
                                            }
                                            segments.push({label: this.fields[i].label, text: text, subtext: []});
                                        } else {
                                            let subtext = [];
                                            for (let j = 0; j < this.data[segment].length; j++) {
                                                let childObject = this.data[segment][j];
                                                let childFields = this.fields[i].childFields;

                                                let combinedVal = '';
                                                for (let childSegment in childObject) {
                                                    if (Object.prototype.hasOwnProperty.call(childObject, childSegment)) {

                                                        for (let k = 0; k < childFields.length; k++) {
                                                            if (childSegment === childFields[k].key) {
                                                                combinedVal += `${childFields[k].label}: ${childObject[childSegment]} `;
                                                            }
                                                        }
                                                    }
                                                }
                                                subtext.push(combinedVal);
                                            }
                                            segments.push({label: this.fields[i].label, text: '', subtext: subtext});
                                        }
                                    }

                                }
                            }
                        }

                return segments;
            }
        },
        methods: {
            closeModal: function () {
                this.$emit('hide-data-modal');
            },
            deleteRow: function () {
                if (confirm('Are you sure you want to delete this data?')) {
                    this.$emit('delete-data-row-'+this.type, this.data);
                }
            },
            reSync: function () {
                this.$emit('resync-'+this.type, this.data);
            },
        }
    }
</script>

<style scoped>

</style>